<template>
    <v-sheet class="pa-0 ma-0 mb-3 rounded-lg" color="grey lighten-2" height="400">
        <v-carousel v-model="current" height="400" hide-delimiter-background delimiter-icon="mdi-minus"
            show-arrows-on-hover :cycle="true" :interval="interval">
            <template v-if="content.length > 0 ">
                <v-carousel-item v-for="(asset, index) in content" :key="index" reverse-transition="fade-transition"
                    transition="fade-transition">
                    <div class="content">
                        <v-card-title>{{ asset.title }} </v-card-title>
                        <v-card-text> {{ asset.descr }}</v-card-text>
                    </div>
                    <v-img v-if="!asset.video" aspect-ratio="1" :src="asset.src" width="100%" max-height="100%"
                        position="center center">
                        <template v-slot:placeholder>
                            <div class="text-muted text-center w-100 h-100 d-flex align-center justify-center">
                                <font-awesome-icon :icon="['fad', 'image']" size="2x" />
                            </div>
                        </template>
                    </v-img>
                    <video v-else-if="current === index" preload="auto" :key="'vbanner-video-' + asset.src"
                        height="100%" autoplay loop muted class="w-100" playsinline>
                        <source :src="asset.src">
                    </video>
                </v-carousel-item>
            </template>
            <template v-else>
                <v-carousel-item v-for="i in 3" :key="i" reverse-transition="fade-transition" height="100%"
                    transition="fade-transition">
                    <div class="text-muted text-center w-100 h-100 d-flex align-center justify-center">
                        <font-awesome-icon v-if="i === 1" :icon="['fad', 'photo-video']" size="2x" />
                        <font-awesome-icon v-else-if="i === 2" :icon="['fad', 'image']" size="2x" />
                        <font-awesome-icon v-else :icon="['fad', 'video']" size="2x" />
                    </div>
                </v-carousel-item>
            </template>
        </v-carousel>
    </v-sheet>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPhotoVideo, faImage, faVideo } from '@fortawesome/pro-duotone-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faPhotoVideo, faImage, faVideo)
export default {
    name: 'NewsSlideShow',
    components: { FontAwesomeIcon },
    data() {
        return {
            loading: false,
            current: 0,
            content: [
                // {
                //     title: "Aerial view of Manhattan Bridge",
                //     descr: "Cinematic drone shot flying over the iconic Manhattan Bridge at sunset",
                //     src: "https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4", // Example of large bridge aerial footage
                //     video: true,
                //     interval: 18
                // },
                // {
                //     title: "Northern Lights in Norway",
                //     descr: "Aurora Borealis dancing across the night sky over Norwegian fjords",
                //     src: "https://storage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4", // Example of night sky timelapse
                //     video: true,
                //     interval: 16
                // },
                // {
                //     title: "Tokyo at Night",
                //     descr: "Vibrant Shibuya crossing with flowing crowds and neon lights",
                //     src: "https://images.unsplash.com/photo-1503899036084-c55cdd92da26",
                //     video: false,
                //     interval: 17
                // },
                // {
                //     title: "Mediterranean Coastline",
                //     descr: "Crystal clear waters meeting white cliffs along the Amalfi Coast",
                //     src: "https://images.unsplash.com/photo-1505118380757-91f5f5632de0",
                //     video: false,
                //     interval: 15
                // },
                // {
                //     title: "Amazon Rainforest",
                //     descr: "Lush canopy view with exotic birds in flight",
                //     src: "https://storage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4", // Example of nature footage
                //     video: true,
                //     interval: 19
                // }
            ]
        }
    },
    computed: {
        interval(){
            return (this.content[this.current]?.interval || 5 ) * 1000;
        }
    },
    methods: {
        getSlideShowAssets(){
            let axios = this.axios.create();
            //REMOVE 3POS HEADERS
            delete axios.defaults.headers.common['3POSAPITOKEN'];
            delete axios.defaults.headers.common['3POSBS'];
            delete axios.defaults.headers.common['3POSBSID'];

            this.loading = true;
            axios.get("https://devapi.3pos.de/slideshowMarketing/", {
                params:{
                    i18n: this.$store.getters['settings/getSettingValue']('geoloc_systemcountry'),
                }
            }).then(res => {
                if (res.status === 200 && res.data) {
                    this.content = res.data;
                }
            }).catch(err => {
                
            }).finally(() => {
                this.loading = false;
            })
        }
    },
    mounted(){
        this.getSlideShowAssets();
    }

}

</script>

<style scoped>
.content {
    background-color: rgba(0,0,0,0.3);
    color: white !important;
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
</style>