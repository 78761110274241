<template>
    <div>
        <!-- <NewsSlideShow /> -->
        <v-container fluid v-if="$store.getters['permissions/checkPermission']('show_dashboardContent')">
            <v-row>
                <v-col cols="12" sm="12" md="12" lg="7">
                    <all-cashier-daily-report-component />
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="5">
                    <notification-component v-if="isProd" />
                    <NewsSlideShow v-else />
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12">
                    <kassen-component />
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12">
                    <sales-chart />
                </v-col>
            </v-row>
            <v-row>
                <v-col v-if="isProd" cols="12">
                    <best-selling-products-component />
                </v-col>
                <template v-else>
                    <v-col cols="12" md="6">
                        <best-selling-products-component />
                    </v-col>
                    <v-col cols="12" md="6">
                        <notification-component />
                    </v-col>
                </template>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12">
                    <activities-component />
                </v-col>
            </v-row>
            <v-row v-if="parseInt(this.settings.settings.migrationEnabled) == 1" no-gutters>
                <v-col cols="12">
                    <MigrateSales />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import SalesChart from "../../components/dashboard/SalesChart";
import AllCashierDailyReport from "../../components/dashboard/AllCashierDailyReportComponent";
import SingleCashierDailyReport from "../../components/dashboard/SingleCashierDailyReport";
// import CurentCashierDailyReport from "../../components/dashboard/CurentCashierDailyReport";
import UsersDailyReport from "../../components/dashboard/UsersDailyReport";
import BestSellingProductsComponent from "../../components/dashboard/BestSellingProductsComponent";
import AllCashierDailyReportComponent from "../../components/dashboard/AllCashierDailyReportComponent";
import NotificationComponent from "../../components/dashboard/NotificationComponent";
import KassenComponent from "../../components/dashboard/KassenComponent";
import ActivitiesComponent from "../../components/dashboard/ActivitiesComponent";
import MigrateSales from "../../components/dashboard/MigrationTest";
import { mapState } from "vuex";
import NewsSlideShow from "../../components/dashboard/NewsSlideShow.vue";

export default {
    components: {
        ActivitiesComponent,
        KassenComponent,
        NotificationComponent,
        AllCashierDailyReportComponent,
        BestSellingProductsComponent,
        UsersDailyReport,
        //CurentCashierDailyReport,
        NewsSlideShow,
        SingleCashierDailyReport,
        AllCashierDailyReport,
        SalesChart,
        MigrateSales
    },

    data: () => ({
        heading: 'Herzlich Willkommen',
        subheading: 'Hier sehen Sie Ihre Kasse im Überblick',
        icon: 'pe-7s-home icon-gradient bg-tempting-azure',
    }),
    computed: {
        ...mapState([
            'pos',
            'settings'
        ]),
        isProd(){
            return process.env.NODE_ENV === 'production';
        }
    }
}
</script>